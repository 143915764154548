import '../Styles/ComoFunciona.css'
import ApiCFCatastroficoFonasa from './ApiCFCatastroficoFonasa'
import ApiCFCatastroficoIsapre from './ApiCFCatastroficoIsapre'
import ApiCFComplementarioIsapre from './ApiCFComplementarioIsapre'
import APICFAccidentes from './APICFAccidentes'
import APICFOncologico from './APICFOncologico'

const ComoFunciona = () => {
    return (
      <div id="cf">
        <div className="mt-4 cfdivprin">
          <br />
          <h1 className="titulos">Cómo usar nuestros seguros y Convenio</h1>
          <h6 className="subtitulos">
            Te mostramos ejemplos de cómo aplican las coberturas según el tipo
            de <span id="subCoberturaPlan">plan</span>  que elijas.
          </h6><br/>
          <section className="container">
            <ul
              className="funcionaTab nav nav-tabs"
              role="tablist"
              style={{ marginLeft: "1rem" }}
            >
              <li className="funcionaTitulo nav-item card" onClick={() => document.getElementById('subCoberturaPlan').textContent='plan'}    >
                <a
                  className="nav-link active cfcardtitulos"
                  data-toggle="tab"
                  href="#catastroficoF"
                >
                  <div>
                    <h6 className="cftitulos">Catastrófico</h6>
                  </div>
                </a>
              </li>
              <li className="funcionaTitulo nav-item card" onClick={() => document.getElementById('subCoberturaPlan').textContent='plan'}    >
                <a
                  className="nav-link cfcardtitulos"
                  data-toggle="tab"
                  href="#catastroficoI"
                >
                  <div>
                    <h6 className="cftitulos">Ambulatorio</h6>
                  </div>
                </a>
              </li>
              <li className="funcionaTitulo nav-item card" onClick={() => document.getElementById('subCoberturaPlan').textContent='cobertura'} >
                <a
                  className="nav-link cfcardtitulos"
                  data-toggle="tab"
                  href="#complementarioI"
                >
                  <div>
                    <h6 className="cftitulos">Hospitalario</h6>
                  </div>
                </a>
              </li>
              <li className="funcionaTitulo nav-item card" onClick={() => document.getElementById('subCoberturaPlan').textContent='plan'}    >
                <a
                  className="nav-link cfcardtitulos"
                  data-toggle="tab"
                  href="#convenio"
                >
                  <div>
                    <h6 className="cftitulos">Convenio de accidente</h6>
                  </div>
                </a>
              </li>
            </ul>
            <div className="tab-content">
             <ApiCFCatastroficoFonasa />              
             <ApiCFCatastroficoIsapre />
             <ApiCFComplementarioIsapre />
             <APICFAccidentes />
              <br/>
            </div>
          </section>
        </div>
      </div>
    );
}
export default ComoFunciona